import { AnchorButton } from '@urbaninfrastructure/react-ui-kit'
import styled from 'styled-components'
import { Anchor } from '../../components'

export const NavLink = styled(Anchor)``

const defaultProps = {
  display: 'block',
  p: 4,
  px: { xl: 3 },
  textDecoration: 'none',
  color: { _: 'primaryContrast', xl: 'link' } as any,
  textAlign: { _: 'right', xl: 'center' },
  fontSize: { _: '26px', xl: '16px' },
  lineHeight: { xl: 1 },
} as typeof NavLink.defaultProps
NavLink.defaultProps = defaultProps

export const NavLinkButton = styled(AnchorButton)``
NavLinkButton.defaultProps = {
  ...defaultProps,
  type: 'button',
} as typeof NavLinkButton.defaultProps
