import React, { useEffect } from 'react'
import Head from 'next/head'
import { defineMessages, useIntl, FormattedMessage } from 'react-intl'
import { Box, Loading, Flex, Link, Anchor, Text } from '../'
import ErrorPage from '../../modules/ErrorPage'
import { useSettingsQuery } from '../../lib/core'
import { checkForError } from '../../lib/util'
import { LoginForm } from './LoginForm'
import Header from './Header'
import Footer from './Footer'
import Intercom from './Intercom'
import { Content, ContentProps, Wrap } from './styled'
import PageLoading from './PageLoading'
import {
  settings_me,
  settings_system,
  settings_company,
} from '../../core-types'
import { useSystemConfig } from '../../lib/use-system-config'
import { useToasts } from '@urbaninfrastructure/toasts'
import { Warning } from '@urbaninfrastructure/react-icons'
import { useRouter } from 'next/router'

type InputProps = {
  type?: 'article' | 'checkout' | 'default' | 'startPage'
  centerContent?: boolean
  title?: string | null
  loading: boolean
  isPublic?: boolean
  render: (renderProps: {
    appContent: boolean
    loading: boolean
    me: settings_me | null
    company: settings_company | null
    system: settings_system
  }) => React.ReactNode
  contentProps?: ContentProps
  hideIntercomLauncher?: boolean
  hideChrome?: boolean
}

const messages = defineMessages({
  titleSuffix: {
    id: 'components.Layout.titleSuffix',
    defaultMessage: '{systemName} for Business',
  },
})

let dialogShown = false

const companyNotActiveToastId = 'company-not-active'
const paymentPathname = '/company/payment'

function useCompanyNotActiveToast() {
  const { data, loading } = useSettingsQuery()
  const isAuthenticated = Boolean(data && data.me)
  const company = data && data.company
  const { addToast, removeToast } = useToasts()
  const router = useRouter()
  useEffect(() => {
    if (dialogShown && router.pathname === paymentPathname) {
      removeToast(companyNotActiveToastId)
    }
  }, [dialogShown, router.pathname])
  useEffect(() => {
    if (
      !isAuthenticated ||
      loading ||
      (company && company.active) ||
      router.pathname === paymentPathname
    ) {
      return
    }
    dialogShown = true
    addToast({
      id: companyNotActiveToastId,
      ttl: 0,
      text: (
        <Flex>
          <Warning color="state.error" mr={2} />
          <Box>
            {(company?.collectionMethod === 'charge_automatically' && (
              <FormattedMessage
                id="company_not_active_charge"
                defaultMessage="You need to add a valid payment method before you can create new voucher codes. Fix this issue on your <link>payment page</link>"
                values={{
                  link: function CompanyPageLink(...chunks) {
                    return (
                      <Link href={paymentPathname}>
                        <Anchor>{chunks}</Anchor>
                      </Link>
                    )
                  },
                }}
              />
            )) ||
              (company?.collectionMethod === 'send_invoice' && (
                <FormattedMessage
                  id="company_not_active_invoice"
                  defaultMessage="Your company needs to be manually approved and you can't create new voucher codes yet. You will receive an e-mail when you have been approved."
                />
              )) || (
                <FormattedMessage
                  id="company_not_active"
                  defaultMessage="Your company have payment issues and can't create new voucher codes. Fix this issue on your <link>payment page</link>"
                  values={{
                    link: function CompanyPageLink(...chunks) {
                      return (
                        <Link href={paymentPathname}>
                          <Anchor>{chunks}</Anchor>
                        </Link>
                      )
                    },
                  }}
                />
              )}
          </Box>
        </Flex>
      ),
    })
  }, [isAuthenticated, company, loading, dialogShown, router.pathname])
}

export default function Layout(props: InputProps) {
  const {
    loading,
    render,
    type,
    centerContent,
    isPublic,
    contentProps,
    hideIntercomLauncher,
    hideChrome,
  } = props
  const intl = useIntl()
  const systemConfig = useSystemConfig()
  const settingsQueryProps = useSettingsQuery()
  const settingsQueryError = settingsQueryProps.error

  useCompanyNotActiveToast()

  const loadingComponent = <Loading />
  if (settingsQueryProps.loading) {
    return <PageLoading />
  }

  const requireAuth = !isPublic
  const me = (settingsQueryProps.data && settingsQueryProps.data.me) || null

  const companyAdministratorNotFound = checkForError(
    'COMPANY_ADMINISTRATOR_NOT_FOUND',
    settingsQueryError
  )

  if (companyAdministratorNotFound) {
    return (
      <ErrorPage
        errorType="companyAdministratorNotFound"
        error={settingsQueryError}
        intercomId={systemConfig.intercomId}
      />
    )
  }

  const isForbidden = checkForError('FORBIDDEN', settingsQueryError)

  if (isForbidden) {
    return (
      <ErrorPage
        errorType="unknown"
        error={settingsQueryError}
        intercomId={systemConfig.intercomId}
      />
    )
  }

  const isAuthenticated =
    (!checkForError('UNAUTHENTICATED', settingsQueryError) && me) || me

  const company =
    (settingsQueryProps.data && settingsQueryProps.data.company) || null

  if (settingsQueryError && settingsQueryError.networkError) {
    return (
      <ErrorPage
        errorType="coreError"
        error={settingsQueryError}
        intercomId={systemConfig.intercomId}
      />
    )
  }

  if (!settingsQueryProps.data) {
    throw new Error('Could not load settings')
  }

  if (!settingsQueryProps.data.system) {
    throw new Error('Could not load system')
  }

  const { system, haveSanityBusinessPage } = settingsQueryProps.data

  const isStartPage = type === 'startPage'
  const showLogin = requireAuth && !isAuthenticated

  let title = intl.formatMessage(messages.titleSuffix, {
    systemName: system.name,
  })

  if (props.title) {
    title = `${props.title} - ${title}`
  }

  return (
    <Wrap>
      {systemConfig.intercomId && (
        <Intercom hideLauncher={hideIntercomLauncher} />
      )}
      <Head>
        <title>{title}</title>
      </Head>
      {!hideChrome && (
        <Header
          title={system.name}
          isStartPage={isStartPage}
          me={me}
          company={company}
          publicDomain={system.publicDomain}
        />
      )}

      {loading && (
        <Wrap flex={1} minHeight={null}>
          <Content center minHeight={null}>
            {loadingComponent}
          </Content>
        </Wrap>
      )}

      {showLogin && (
        <Content center>
          <LoginForm />
          <Text as="p" textAlign="center">
            <FormattedMessage
              id="components.LoginForm.description"
              defaultMessage="Log in for companies that want to buy and administer voucher codes. {registrationLink} to get access"
              values={{
                registrationLink: (
                  <Link href="/registration">
                    <Anchor>
                      <FormattedMessage
                        id="components.LoginForm.registrationLink"
                        defaultMessage="Register your company"
                      />
                    </Anchor>
                  </Link>
                ),
              }}
            />
          </Text>
          {haveSanityBusinessPage && system.webUrl && (
            <Text as="p" textAlign="center">
              <FormattedMessage
                id="components.LoginForm.readMore"
                defaultMessage="Read more about our {readMoreLink} on our web page."
                values={{
                  readMoreLink: (
                    <Anchor href={`${system.webUrl}/business`}>
                      <FormattedMessage
                        id="components.LoginForm.readMoreLink"
                        defaultMessage="business solution"
                      />
                    </Anchor>
                  ),
                }}
              />
            </Text>
          )}
        </Content>
      )}
      {!showLogin && !loading && (
        <Content center={centerContent} {...contentProps}>
          {render({
            loading,
            me,
            company,
            system,
            appContent: false,
          })}
        </Content>
      )}

      {!hideChrome && <Footer system={system} me={me} />}
    </Wrap>
  )
}
