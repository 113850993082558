import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import styled, { css, keyframes } from 'styled-components'
import { Flex, Box, Button, Hide, Anchor, Heading, Link, Container } from '../'
import Logo from '../Logo'
import {
  Menu as MenuIcon,
  CloseAlt as CloseMenuIcon,
} from '@urbaninfrastructure/react-icons'
import Navigation from './Navigation'
import { settings_me, settings_company } from '../../core-types'

type Props = {
  title: string | null
  isStartPage: boolean
  publicDomain: string | null
  me: settings_me | null
  company: settings_company | null
}

const messages = defineMessages({
  closeMenu: {
    id: 'components.Layout.Header.menuClose',
    defaultMessage: 'Close menu',
  },
  openMenu: {
    id: 'components.Layout.Header.menu',
    defaultMessage: 'Menu',
  },
})

const fontSizeKey = 2

const wrapperAnimation = keyframes`
0%   { background-color: transparent; }

100% { background-color: ${
  // @ts-ignore
  ({ theme }) => {
    return theme.colors.primary
  }
}; }`

const wrapperBeforeAnimation = keyframes`
0%   { transform: scale(0.1) }
100% { transform: scale(1.1) }`

const HeaderBox = styled(Box)<{ menuOn: boolean }>`
  ${({ theme, menuOn }) => css`
    z-index: 2;
    ${menuOn &&
    css`
      background-color: ${theme.colors.primary};
      color: white;
      min-height: 100vh;
      animation: ${wrapperAnimation} 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22);
      animation-fill-mode: both;
      position: relative;
      overflow: hidden;
      /* Background circle animation on toggeling */
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -95vmax;
        right: -95vmax;
        width: 200vmax;
        height: 200vmax;
        background: ${theme.colors.primary};
        border-radius: 50%;
        animation: ${wrapperBeforeAnimation} 0.5s
          cubic-bezier(0.165, 0.84, 0.44, 1);
        animation-fill-mode: both;
      }
    `};
  `};
`

HeaderBox.displayName = 'HeaderBox'

const ToggleMenuButton = styled(Button)<{
  isStartPage: boolean
  navLinkColor: string
}>`
  ${({ theme, navLinkColor, isStartPage, ...props }) =>
    css`
      display: flex;
      justifycontent: center;
      alignitems: center;
      background-color: transparent;
      color: ${theme.colors[navLinkColor]};
      &:hover,
      &:focus {
        background-color: ${isStartPage
          ? `rgba(0, 0, 0, 0.2)`
          : theme.colors.neutral[1]};
        color: ${theme.colors[navLinkColor]};
        outline: none;
      }

      ${props['aria-expanded'] &&
      css`
        background-color: transparent;
        color: white;
        z-index: 2;
        position: relative;
        &:hover,
        &:focus {
          background-color: ${theme.colors.primaryDark};
          color: white;
        }
      `};
    `};
`

const menuItemsAnimation = keyframes`
0%   { opacity: 0; transform: translateX(20%);  }
100% { opacity: 1; transform: translateX(0); }
`

const MenuFlexWrapper = styled(Flex)<{ menuOn: boolean }>`
  ${({ theme, menuOn }) =>
    css`
      display: none;
      ${theme.mediaQueries[3]} {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      ${menuOn &&
      css`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: ${theme.space[5]}px 0;
        & > * > * {
          animation: ${menuItemsAnimation} 0.3s cubic-bezier(0.19, 1, 0.22, 1);
          animation-fill-mode: both;
          &:nth-child(1) {
            animation-delay: 0.1s;
          }
          &:nth-child(2) {
            animation-delay: 0.15s;
          }
          &:nth-child(3) {
            animation-delay: 0.2s;
          }
          &:nth-child(4) {
            animation-delay: 0.25s;
          }
          &:nth-child(5) {
            animation-delay: 0.3s;
          }
          &:nth-child(6) {
            animation-delay: 0.35s;
          }
        }
      `};
    `};
`

function Header({ me, company, title, isStartPage, publicDomain }: Props) {
  const intl = useIntl()
  const [menuOn, setMenuOn] = React.useState(false)
  const textColor = 'text'
  const navLinkColor = { _: 'primaryContrast', xl: 'link' } as any

  return (
    <HeaderBox
      color={textColor}
      fontSize={fontSizeKey}
      position={isStartPage ? 'absolute' : 'relative'}
      width={1}
      menuOn={menuOn}
    >
      <Container bleed fullWidth px="xs">
        <Flex
          alignItems={{ xl: 'center' }}
          flexDirection={{ _: 'column', xl: 'row' }}
          mx="auto"
        >
          <Flex position="relative" alignItems="center" flex="1 0 auto">
            <Box flex="1 0 auto">
              <Heading
                fontSize={fontSizeKey}
                mb={0}
                py="xxs"
                fontWeight="normal"
                style={{ opacity: menuOn ? 0 : 1 }}
              >
                <Link href="/" passHref>
                  <Anchor
                    py={3}
                    textDecoration="none"
                    display="inline-block"
                    color={navLinkColor}
                  >
                    <Logo
                      title={title}
                      color={navLinkColor}
                      companyName={company && company.name}
                    />
                  </Anchor>
                </Link>
              </Heading>
            </Box>

            <Hide xl>
              <ToggleMenuButton
                onClick={() => {
                  setMenuOn(!menuOn)
                }}
                aria-expanded={menuOn}
                my={3}
                ml="auto"
                variant="reset"
                small
                isStartPage={isStartPage}
                navLinkColor={navLinkColor}
                aria-label={
                  intl &&
                  (menuOn
                    ? intl.formatMessage(messages.closeMenu)
                    : intl.formatMessage(messages.openMenu))
                }
              >
                {menuOn ? <CloseMenuIcon /> : <MenuIcon />}
              </ToggleMenuButton>
            </Hide>
          </Flex>

          <MenuFlexWrapper menuOn={menuOn}>
            <Navigation
              navLinkProps={{ color: navLinkColor }}
              me={me}
              company={company}
              mobileMenu={menuOn}
              publicDomain={publicDomain}
            />
          </MenuFlexWrapper>
        </Flex>
      </Container>
    </HeaderBox>
  )
}

export default Header
