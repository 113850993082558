import React from 'react'
import { defineMessage, FormattedMessage } from 'react-intl'

export const createCodesMessage = defineMessage({
  id: 'createCodes',
  defaultMessage: 'Create codes',
})

export const valueCodesPluralMessage = defineMessage({
  id: 'valueCodesPlural',
  defaultMessage: '{count, plural, one {voucher code} other {voucher codes}}',
})

export function ValueCodesPluralMessage({ count }: { count: number }) {
  return (
    <FormattedMessage<{ count: number }>
      {...valueCodesPluralMessage}
      values={{ count }}
    />
  )
}
