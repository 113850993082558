import React from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { Text, Button, Box, Anchor, Container, Flex } from '../'
import { useUpdateMeMutation } from '../../lib/core'
import { settings_me, settings_system } from '../../core-types'
import { useLocale } from '../../lib/use-locale'
import SocialIcon from '../SocialMediaIcon'
import { isInurbaRouen } from '../../lib/util'
import { useSystemConfig } from '../../lib/use-system-config'

type Props = {
  me: settings_me | null
  system: settings_system
}

const FooterBox = styled(Box)`
  ${({ theme }) => {
    return css`
      color: ${theme.footer.color};
      background-color: ${theme.footer.backgroundColor};
      a {
        color: ${theme.footer.color};
      }
    `
  }};
`

const FooterGrid = styled(Box)`
  ${({ theme }) => {
    return css`
      @media screen and (min-width: ${theme.breakpoints[0]}) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -${theme.space[5]}px;
      }
    `
  }};
`

const FooterCell = styled(Box)`
  ${({ theme }) => {
    return css`
      @media screen and (min-width: ${theme.breakpoints[0]}) {
        flex-basis: 50%;
        max-width: 50%;
        padding: 0 ${theme.space[5]}px;
      }
      @media screen and (min-width: ${theme.breakpoints[3]}) {
        flex-basis: 50%;
        max-width: 50%;
      }
    `
  }};
`
FooterCell.defaultProps = {
  mb: 'md',
}

const LanguageButton = styled(Button)`
  ${({ theme }) => {
    return css`
      display: block;
      color: ${theme.footer.color};
      padding: 0;
      text-decoration: none;
      &:hover,
      &:focus {
        color: ${theme.footer.color};
        text-decoration: underline;
      }
    `
  }};
`

LanguageButton.defaultProps = {
  variant: 'link',
}

const longLocaleMessages = {
  en: 'Switch to English',
  nb: 'Bytt til norsk',
  it: "Passa all'italiano",
  fr: 'Passer au français',
  pl: 'Przełącz na polski',
  // de: 'Zu Deutsch wechseln',
  // sv: 'Byt till svenska',
  // uk: 'Переключитися на українську',
}

function ChangeLocaleButton({
  system,
  me,
  locale,
  ...props
}: {
  system: settings_system | null
  me: settings_me | null
  locale: string
}) {
  const { setLocale } = useLocale()
  const onCompleted = () => {
    window.location.reload()
  }
  const [updateMe] = useUpdateMeMutation({
    onCompleted,
  })

  if (!system || !system.languageCodes) {
    return null
  }

  let preferredLanguageCode = locale

  if (me && me.preferredLanguageCode) {
    preferredLanguageCode = me.preferredLanguageCode
  }

  const availableLanguages = system.languageCodes.filter(
    (lang) => lang !== preferredLanguageCode
  )

  return (
    <FooterCell as="section">
      <Text small mb="xs" as="h2" fontWeight="normal">
        <FormattedMessage
          id="components.Layout.Footer.language"
          defaultMessage="Language"
        />
      </Text>
      {availableLanguages.map(
        (lang) =>
          longLocaleMessages[lang] && (
            <LanguageButton
              key={`language-switch-${lang}`}
              onClick={() => {
                setLocale(lang)
                if (me) {
                  updateMe({
                    variables: {
                      input: {
                        preferredLanguageCode: lang,
                      },
                    },
                  })
                } else {
                  onCompleted()
                }
              }}
              {...props}
            >
              {longLocaleMessages[lang]}
            </LanguageButton>
          )
      )}
    </FooterCell>
  )
}

const Footer = ({ system, me }: Props) => {
  const intl = useIntl()
  const systemConfig = useSystemConfig()
  const inurbaRouen = isInurbaRouen(systemConfig)
  return (
    <FooterBox py={[5, 6, 7]} flex="none" as="footer">
      <Container>
        <FooterGrid as="nav">
          <ChangeLocaleButton system={system} me={me} locale={intl.locale} />
          <FooterCell as="section">
            <Text small mb="xs" as="h2" fontWeight="normal">
              <FormattedMessage
                id="components.Layout.Footer.help"
                defaultMessage="Help"
              />
            </Text>
            <Text as="p" small>
              <FormattedMessage
                id="components.Layout.Footer.description"
                defaultMessage="Contact us on phone {contactNumber} or email {contactEmail}"
                values={{
                  contactNumber: system.contactNumber,
                  contactEmail: (
                    <Anchor href={`mailto:${system.contactEmail}`}>
                      {system.contactEmail}
                    </Anchor>
                  ),
                }}
              />
            </Text>
            {/* TODO this is should be configured through the Sanity, not hardcoded!!! */}
            {inurbaRouen && (
              <Box>
                <Flex>
                  <Text as="p" small>
                    <FormattedMessage
                      defaultMessage="Follow us on social media"
                      id="footer.FollowUsOnSocialMedia"
                    />
                  </Text>
                </Flex>
                <Flex>
                  <Box mr={4}>
                    <Anchor
                      href="https://www.instagram.com/lovelo_rouen"
                      target="blank"
                    >
                      <SocialIcon name={'instagram'} color="#fff" />
                    </Anchor>
                  </Box>
                  <Box>
                    <Anchor
                      href="https://www.facebook.com/lovelo.rouen"
                      target="blank"
                    >
                      <SocialIcon name={'facebook'} color="#fff" />
                    </Anchor>
                  </Box>
                </Flex>
              </Box>
            )}
          </FooterCell>
        </FooterGrid>
      </Container>
    </FooterBox>
  )
}

export default Footer
