import React from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import {
  Down as IconDown,
  Up as IconUp,
} from '@urbaninfrastructure/react-icons'
import { List, Li, Anchor, Link, Button, Flex, Text } from '../'
import Popover from '../Popover'
import { createCodesMessage } from '../i18n'
import { pushRoute } from '../Link'
import { settings_company, settings_me } from '../../core-types'
import { useTenantAuth } from '../../lib/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useApolloClient } from '@apollo/react-hooks'
import { NavLink, NavLinkButton } from './NavLink'

type NavLinkProps = {
  color?: string
}

const messages = defineMessages({
  valueCodesIndex: {
    id: 'components.Layout.Navigation.Link.ValueCodesIndex',
    defaultMessage: 'Your codes',
  },
  valueCodesReceipts: {
    id: 'components.Layout.Navigation.Link.ValueCodesReceipts',
    defaultMessage: 'Receipts',
  },
  loggedInAs: {
    id: 'components.Layout.Navigation.loggedInAsn',
    defaultMessage: 'Logged in as',
  },
  logIn: {
    id: 'components.Layout.Navigation.Link.LogIn',
    defaultMessage: 'Log in',
  },
  logOut: {
    id: 'components.Layout.Navigation.Link.LogOut',
    defaultMessage: 'Log out',
  },
  homepage: {
    id: 'components.Layout.Navigation.Link.Homepage',
    defaultMessage: 'Homepage',
  },
})

const Navigation = ({
  navLinkProps,
  company,
  mobileMenu,
  publicDomain,
  me,
}: {
  navLinkProps: NavLinkProps
  mobileMenu: boolean
  publicDomain: string | null
  me: settings_me | null
  company: settings_company | null
}) => {
  const auth = useTenantAuth(me?.preferredLanguageCode || null)
  const client = useApolloClient()
  const [user] = useAuthState(auth)
  const intl = useIntl()
  const loggedInItems = [
    {
      href: '/',
      title: intl.formatMessage(createCodesMessage),
    },
    {
      href: '/value-codes',
      title: intl.formatMessage(messages.valueCodesIndex),
    },
    {
      href: '/receipts',
      title: intl.formatMessage(messages.valueCodesReceipts),
    },
  ]

  const publicItems = [
    {
      href: '/',
      title: intl.formatMessage(messages.logIn),
    },
  ]

  const items = user ? loggedInItems : publicItems

  return (
    <List display={{ _: 'block', xl: 'flex' }}>
      {!user && publicDomain && (
        <Li>
          <NavLink href={`https://${publicDomain}/`} {...navLinkProps}>
            <FormattedMessage {...messages.homepage} />
          </NavLink>
        </Li>
      )}
      {items.map(({ href, title }) => {
        return (
          <Li key={href}>
            <Link href={href}>
              <NavLink {...navLinkProps}>{title}</NavLink>
            </Link>
          </Li>
        )
      })}
      {user && (
        <>
          <Li>
            <Popover
              initialIsOpen={false}
              // @ts-ignore
              renderToggler={({ isOpen, ...props }) => {
                return (
                  <NavLinkButton {...props} {...navLinkProps}>
                    <FormattedMessage
                      defaultMessage="Account"
                      id="components.Layout.Header.account"
                    />
                    {!isOpen && <IconDown size={12} ml={2} />}
                    {isOpen && <IconUp size={12} ml={2} />}
                  </NavLinkButton>
                )
              }}
            >
              {() => {
                return (
                  <div>
                    <Text small>
                      <FormattedMessage {...messages.loggedInAs} />
                    </Text>
                    <Text mb="xxs">{user.displayName}</Text>
                    <List>
                      <Li>
                        <Link href="/administrators">
                          <Anchor>
                            <FormattedMessage
                              defaultMessage="Administrators"
                              id="components.Layout.Header.loggedInMenu.administrators"
                            />
                          </Anchor>
                        </Link>
                      </Li>
                      {company && (
                        <Li>
                          <Link href="/company">
                            <Anchor>{company.name}</Anchor>
                          </Link>
                        </Li>
                      )}
                    </List>
                  </div>
                )
              }}
            </Popover>
          </Li>
          <Li>
            <NavLinkButton
              {...navLinkProps}
              onClick={async () => {
                await auth.signOut()
                pushRoute({
                  pathname: '/',
                })
                await client.resetStore()
              }}
            >
              <FormattedMessage {...messages.logOut} />
            </NavLinkButton>
          </Li>
        </>
      )}
      {!user && (
        <Li>
          <Flex alignItems="center" style={{ height: '100%' }}>
            <Link href="/registration" passHref>
              <Button
                as="a"
                variant={mobileMenu ? 'inverted' : 'primary'}
                small
                shape="cool"
                mt={{ xs: 7, xl: 0 }}
                ml={{ xs: 0, xl: 4 }}
              >
                <FormattedMessage
                  defaultMessage="Register"
                  id="components.Layout.Header.RegisterButton"
                />
              </Button>
            </Link>
          </Flex>
        </Li>
      )}
    </List>
  )
}

export default Navigation
